<template>
    <div class="games">
        <Top/>
        <Menu/>
        <div class="games_content">
            <div v-if="!isGameTask" class="games_content01">
                <div class="games_content01_01" v-for="(index, key) in 5" @click="toGameTask()">
                    <img src="../../assets/images/game_tp.jpg"/>
                </div>
            </div>

            <div v-if="isGameTask" class="games_content02">
                <div class="games_content02_01">
                    <div class="games_content02_011" @click="returnGameTask()"><i class="el-icon-d-arrow-left"></i></div>
                    <div>Social</div>
                    <div>Task</div>
                </div>

                <div class="games_content02_02">

                    <div class="games_content02_02_01" v-for="(index, key) in 3">
                        <div class="games_content02_02_011">
                            <div class="games_content02_02_0111">
                                <img src="../../assets/images/htg_tp.jpg"/>
                            </div>
                            <div class="games_content02_02_0112">
                                <div class="games_content02_02_01121">Join Telegram channel</div>
                                <div class="games_content02_02_01122">+100 GC</div>
                            </div>
                        </div>
                        <div class="games_content02_02_012">
                            <div v-show="true" class="start02_012" @click="onGameStartEarn()">Start</div>
                            <div v-show="false" class="claim02_012" @click="onGameClaimInvite()">Claim</div>
                        </div>
                    </div>

                    <div class="games_content02_02_01">
                        <div class="games_content02_02_011">
                            <div class="games_content02_02_0111">
                                <img src="../../assets/images/htg_tp.jpg"/>
                            </div>
                            <div class="games_content02_02_0112">
                                <div class="games_content02_02_01121">Join Telegram channel</div>
                                <div class="games_content02_02_01122">+100 GC</div>
                            </div>
                        </div>
                        <div class="games_content02_02_012">
                            <div v-show="false" class="start02_012" @click="onGameStartEarn()">Start</div>
                            <div v-show="true" class="claim02_012" @click="onGameClaimInvite()">Claim</div>
                        </div>
                    </div>

                    <div class="games_content02_02_01">
                        <div class="games_content02_02_011">
                            <div class="games_content02_02_0111">
                                <img src="../../assets/images/twitter_logo.jpg"/>
                            </div>
                            <div class="games_content02_02_0112">
                                <div class="games_content02_02_01121">Join Twitter channel</div>
                                <div class="games_content02_02_01122">+100 GC</div>
                            </div>
                        </div>
                        <div class="games_content02_02_012">
                            <div v-show="true" class="start02_012" @click="onGameStartEarn()">Start</div>
                            <div v-show="false" class="claim02_012" @click="onGameClaimInvite()">Claim</div>
                        </div>
                    </div>

                    <div class="games_content02_02_01">
                        <div class="games_content02_02_011">
                            <div class="games_content02_02_0111">
                                <img src="../../assets/images/twitter_logo.jpg"/>
                            </div>
                            <div class="games_content02_02_0112">
                                <div class="games_content02_02_01121">Join Twitter channel</div>
                                <div class="games_content02_02_01122">+100 GC</div>
                            </div>
                        </div>
                        <div class="games_content02_02_012">
                            <div v-show="false" class="start02_012" @click="onGameStartEarn()">Start</div>
                            <div v-show="true" class="claim02_012" @click="onGameClaimInvite()">Claim</div>
                        </div>
                    </div>

                    <div class="games_content02_02_01">
                        <div class="games_content02_02_011">
                            <div class="games_content02_02_0111">
                                <img src="../../assets/images/f_tp.jpg"/>
                            </div>
                            <div class="games_content02_02_0112">
                                <div class="games_content02_02_01121">Join Facebook channel</div>
                                <div class="games_content02_02_01122">+100 GC</div>
                            </div>
                        </div>
                        <div class="games_content02_02_012">
                            <div v-show="true" class="start02_012" @click="onGameStartEarn()">Start</div>
                            <div v-show="false" class="claim02_012" @click="onGameClaimInvite()">Claim</div>
                        </div>
                    </div>

                    <div class="games_content02_02_01">
                        <div class="games_content02_02_011">
                            <div class="games_content02_02_0111">
                                <img src="../../assets/images/f_tp.jpg"/>
                            </div>
                            <div class="games_content02_02_0112">
                                <div class="games_content02_02_01121">Join Facebook channel</div>
                                <div class="games_content02_02_01122">+100 GC</div>
                            </div>
                        </div>
                        <div class="games_content02_02_012">
                            <div v-show="false" class="start02_012" @click="onGameStartEarn()">Start</div>
                            <div v-show="true" class="claim02_012" @click="onGameClaimInvite()">Claim</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Top from "../../components/framing/Top";
    import Menu from "../../components/framing/Menu";
    import request from "../../tools/request";

    export default {
        name: "Index",
        components: {Top, Menu},
        data() {
            return {
                userId: 0,
                isGameTask: false,
            }
        },
        watch: {},
        created() {
            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
            }
        },
        mounted() {
            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
            }

            this.getVerifyUserLogin();
        },
        destroyed() {
        },
        methods: {
            async getVerifyUserLogin() {
                if (sessionStorage.getItem("userId")) {
                    this.userId = sessionStorage.getItem("userId");

                    await request.getVerifyUserLogin(sessionStorage.getItem("userId")).then((res) => {
                        if (!res.data) {
                            this.$router.push("/login");
                        }
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                } else {
                    this.$router.push("/login");
                }
            },
            toGameTask() {
                this.isGameTask = true;
            },
            returnGameTask() {
                this.isGameTask = false;
            },
            onGameStartEarn() {
                this.$message.warning("Coming soon!");
                return;
            },
            onGameClaimInvite() {
                this.$message.warning("Coming soon!");
                return;
            }
        }
    }
</script>

<style lang="less">
    .games {
        background-color: #131313;
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;

        .games_content {
            position: absolute;
            top: 100px;
            bottom: 100px;
            width: 100%;
            .games_content01::-webkit-scrollbar {
                width: 0;
            }
            .games_content01 {
                overflow: hidden;
                overflow-y: auto;

                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                .games_content01_01 {
                    width: 380px;
                    height: 220px;
                    margin: 20px 0;
                    cursor: pointer;
                    img {
                        width: 100%;
                        height: 220px;
                        border-radius: 10px;
                    }
                }
            }

            .games_content02 {
                height: 100%;
                width: 100%;
                .games_content02_01 {
                    height: 100px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: nowrap;
                    border-bottom: darkgray solid 0.5px;
                    div {
                        margin: 20px 10px;
                        color: white;
                        width: 150px;
                        height: 50px;
                        line-height: 50px;
                        border: darkgray solid 1px;
                        border-radius: 10px;
                        text-align: center;
                        cursor: pointer;
                    }
                    .games_content02_011 {
                        margin: 20px 10px;
                        color: white;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        border: #027d93 solid 1px;
                        border-radius: 10px;
                        text-align: center;
                        cursor: pointer;
                    }
                }

                .games_content02_02::-webkit-scrollbar {
                    width: 0;
                }
                .games_content02_02 {
                    overflow: hidden;
                    overflow-y: auto;

                    width: 100%;
                    height: calc(100% - 100px);
                    .games_content02_02_01 {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: space-around;

                        height: 85px;
                        .games_content02_02_011 {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: center;
                            .games_content02_02_0111 {
                                margin: 10px;
                                img {
                                    width: 45px;
                                    border: 1px solid #000;
                                    border-radius: 50%;
                                }
                            }
                            .games_content02_02_0112 {
                                color: white;
                                .games_content02_02_01121 {
                                    height: 25px;
                                    line-height: 30px;
                                }
                                .games_content02_02_01122 {
                                    height: 35px;
                                    line-height: 35px;
                                }
                            }
                        }
                        .games_content02_02_012 {
                            .start02_012 {
                                width: 60px;
                                height: 30px;
                                background: white;
                                border-radius: 10px;
                                border: darkgray solid 0.5px;
                                color: #027d93;
                                font-size: 14px;
                                line-height: 30px;
                                text-align: center;
                                cursor: pointer;
                            }
                            .claim02_012 {
                                width: 60px;
                                height: 30px;
                                background: #027d93;
                                border-radius: 10px;
                                border: darkgray solid 0.5px;
                                color: white;
                                font-size: 14px;
                                line-height: 30px;
                                text-align: center;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
